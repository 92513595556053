<template>
  <b-card title="User Timeline">
    <app-timeline>

      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item>
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Contract signed</h6>
          <small class="text-muted">12 min ago</small>
        </div>
        <p>Contract signed, transaction pending payment</p>
        <p class="mb-0">
          <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          />
          <span class="align-bottom">invoice.pdf</span>
        </p>
      </app-timeline-item>
      <app-timeline-item variant="warning">

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Client Quotation</h6>
          <small class="text-muted">45 min ago</small>
        </div>
        <p>Sent via email, signature required</p>
      </app-timeline-item>
      <app-timeline-item
        variant="info"
        title="Create a new client by Salesperson: <>"
        subtitle="Add transaction record"
        time="2 days ago"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BImg,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BImg,
    AppTimeline,
    AppTimelineItem,
  },
}
</script>

<style>

</style>
