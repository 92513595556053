<template>
  <div>
    <b-card
      v-if="userData.role === 'client' "
      no-body
      class="border-primary"
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">
          Current Status
        </h5>
        <b-badge variant="light-primary">
          {{ userData.metadata.role }}
        </b-badge>
        <small class="text-muted w-100">July 22, 2021</small>
      </b-card-header>

      <b-card-body>
        <ul class="list-unstyled my-1">
          <li>
            <span class="align-middle">Royalty points: {{ (userData.metadata.rawData.meta_data.find(md => md.key === 'mycred_default_total')).value }}</span>
          </li>
          <li class="my-25">
            <span class="align-middle">Rank: {{ (userData.metadata.rawData.meta_data.find(md => md.key === 'mycred_rank')).value }}</span>
          </li>
        </ul>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
        >
          Edit Plan
        </b-button>
      </b-card-body>
    </b-card>
    <b-card
      v-else
      no-body
      class="border-primary"
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">
          Current Plan
        </h5>
        <b-badge variant="light-primary">
          Basic
        </b-badge>
        <small class="text-muted w-100">July 22, 2021</small>
      </b-card-header>

      <b-card-body>
        <ul class="list-unstyled my-1">
          <li>
            <span class="align-middle">5 Users</span>
          </li>
          <li class="my-25">
            <span class="align-middle">10 GB storage</span>
          </li>
          <li>
            <span class="align-middle">Basic Support</span>
          </li>
        </ul>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
        >
          Upgrade Plan
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
